<template>
  <div class="addCar">
     <CarForm :CarData="carList"/>
  </div>
</template>

<script>
// @ is an alias to /src
import CarForm from '@/components/CarForm.vue'

export default {
  name: 'About',
  components: {
    CarForm
  }
}
</script>
<style>
.addCar{
  padding-top: 50px;
}
</style>