<template>
  <div class="login_body">

      <div class="login_container">

          <div class="login_container_inside">
                <h1>Logowanie</h1>

                <hr class="login_hr"/>

                <form @submit.prevent="login">

                    <input class="input_login" type="text" placeholder="Email" v-model="user.email"/> <br />
                    <input class="input_login" type="password" placeholder="Hasło" v-model="user.password" />
                    
                    <div class="login_button_container">
                    
                        <button type="submit" class="login_button" >Login</button>

                    </div>

                </form>

                    <div v-if="error" class="error_message">
                        <p>{{error}}</p>
                    </div>
                   

          </div>


      </div>


  </div>
</template>

<script>


export default {
  name: 'Login',
  data(){
      return{
          user:{
              email: null,
              password: null
          },
          error: null
      }
  },
  methods:{
      async login(){
         
         if(this.user.email === null || this.user.email ===''){
             this.error = "Nie wypełniono pola Email.";
         }else if(this.user.password === null || this.user.password === ''){
             this.error = "Nie wprowadzono hasła.";
         }else{
             this.error = null;
         }
         
         if(this.error === null){
            
            try{

              await this.$store.dispatch('login', this.user);
              this.error = null;
              await this.$router.push({name: 'Home' });
              window.location.reload();

            }catch{
                this.error = "Błędny Email lub hasło.";
            }
         }
          
      }
  }
}
</script>

<style scoped>
.login_body{
    min-height: 100vh;
    width: 100%;
    background-image: linear-gradient(rgba(7, 7, 7,.7), rgba(209, 209, 209, 0.1)), url('~@/assets/bgTopGear.jpg');
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
   
}
.login_container{
    width: 90%;
    margin: auto;
    max-width: 500px;
    background: rgba(255,255,255,.6);
    min-height: 60vh;
    border-radius: 20px;
    border: 5px solid #254455;
    display: flex;
}
h1{
    margin: -20px 0 0 0;
    color: #254455;
}
.login_container_inside{
    width: 100%;
    position: relative;
    margin: auto;
    padding: 30px 5px;
}
.login_hr{
    width: 60%;
    max-width: 300px;
    border: 1px solid rgb(43, 43, 43);
    background-color: rgb(43, 43, 43);
    margin: 20px auto 40px auto;
}
.input_login{
    width: 80%;
    max-width: 300px;
    height: 25px;
    border: none;
    border-radius: 6px;
    padding: 5px;
    margin-bottom: 20px;
    background: rgba(255,255,255,.8);
    transition: .3s;
}
.input_login:focus{
    outline: none;
    border: 2px solid #254455;
}
.login_button{
    width: 80%;
    max-width: 315px;
    height: 40px;
    margin-top: 15px;
    background: #254455;;
    border: none;
    border-radius: 6px;
    color: white;
    cursor: pointer;
}
.error_message{
    margin-top: 20px;
}
</style>