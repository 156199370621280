<template>
    <div class="form_wrapper">
       
        <div class="container">

            <div class="container_header">
                Dodawanie nowego pojazdu

                <hr class="container_header_hr" />
            </div>

            <form @submit.prevent="addCar">
                 <div class="form">
                    <input class="input_form" type="text" v-model="Car.CarMake" placeholder="Marka"/>
                    <input class="input_form" type="text" v-model="Car.Model" placeholder="Model"/>
                    <input class="input_form" type="text" v-model="Car.Year" placeholder="Rok produkcji"/>
                    <input class="input_form" type="text" v-model="Car.CarPlate" placeholder="Nr Rejestracyjny"/>
                    <input class="input_form" type="text" v-model="Car.CarReviewDateTo" placeholder="Przegląd do"/>
                    <input class="input_form" type="text" v-model="Car.InsuranceTo" placeholder="Ubezpieczenie do"/>
                    <input class="input_form" type="text" v-model="Car.OilChangeDate" placeholder="Data ostatniej wymiany oleju"/>
                    <input class="input_form last_input" type="text" v-model="Car.Milage" placeholder="Przebieg"/>
    
                </div>

                   <div v-if="error" class="error_message">
                        <p>{{error}}</p>
                    </div>

                <div class="container_buttons">
                    <hr class="container_header_hr" />
                    <button class="button_send">Dodaj</button>
                </div>
            </form>

        </div>

    </div>
</template>
<script>
//import {Trash2Icon , EditIcon} from '../assets/Icons/Icons'
import repository from '../api/repository';

export default{
    name: 'CarForm',
    data(){
        return{
            Car:{
                CarMake: '',
                Model: '',
                Year: '',
                CarPlate: '',
                CarReviewDateTo: '',
                InsuranceTo: '',
                OilChangeDate: '',
                Milage: '',
            },
            error: ''
        }
    },  
    methods:{
        async addCar(){
     
            try{
                await repository.addCar({"car": this.Car});
                await this.$router.push({name: 'Home' });
                window.location.reload();
            }catch(e){
                let err = Object.values(e.response.data.errors)[0];                 
                this.error = err[0];
            }
        }
    }
   /* props:{
        CarData: []
    },
    components:{
        Trash2Icon,
        EditIcon
    }*/
}
</script>
<style scoped>
.container_header{
    text-align: left;
    font-size: 18px;
    font-weight: 600;
}
.container_header_hr{
    margin-top: 10px;
}
.form{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 25px;
}
@media(min-width:1433px){
    .form::after{
        content: '';
        flex: auto;
    }
    .last_input{
        margin-left: 1.05%!important;
    }
}
.input_form{
    width: calc(90% - 20px);
    max-width: 400px;
    height: 25px;
    border: none;
    border-radius: 6px;
    padding: 5px;
    margin-bottom: 20px;
    background: rgba(255,255,255,.8);
    margin: 10px;
}
.input_form:focus{
    outline: none;
    border: none;
}
.container_buttons{
    margin-top: 40px;
}
.button_send{
    height: 40px;
    width: 200px;
    border-radius: 7px;
    border: none;
    background:rgb(231, 231, 231);
    font-weight: 600;
    margin-top: 20px;
    cursor: pointer;
}
</style>